import { merge } from 'lodash'
import themeSettings from '~/themes/virtshop/settings'

const overloadSettings = {
  checkout: {
    enablePromoCode: false,
  },
  components: {
    AddToCartModalItemAdded: {
      displayedProducts: 'recommended',
    },
    PageCatalog: {
      badgeWidgetsInSidebar: {
        enableOn: ['desktop'],
      },
    },
    PageHomepage: {
      showHomepageBranches: false,
      showTopCategories: true,
      topCategories: {
        enable: true,
        fullViewportWidthWrapper: false,
      },
    },
    PageProductDetail: {
      showAvailability: true,
      showAvailabilityInWarehouses: false,
      showAvailabilityInWarehousesInDeliveryOptions: false,
      showDeliveryOptions: true,
      showDateOfStorage: true,
      badgeWidgetsInSidebar: {
        enableOn: ['desktop'],
      },
    },
    PageCheckoutMethods: {
      enableInsurance: false,
    },
    PageContact: {
      showBranches: false,
    },
    EntryModal: {
      enable: false,
    },
  },
  layouts: {
    LayoutBase: {
      footerBadgeWidgets: {
        enableOn: ['mobile', 'tablet'],
      },
    },
  },
}

export default merge(themeSettings, overloadSettings)
