import { mutations, state as parentState } from '~/sites/virtshop/store/locale'

const state = () => ({
  ...parentState(),
  canonicalDomains: [
    { language: 'cs', domain: 'https://www.eroticke-pomucky.cz' },
    { language: 'sk', domain: 'https://www.eros.sk' },
  ],
})

export { mutations, state }
