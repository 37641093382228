import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from 'ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _72eddf6c = () => interopDefault(import('../sites/dashsexshop/pages/user/activate.vue' /* webpackChunkName: "sites/dashsexshop/pages/user/activate" */))
const _7f7e259c = () => interopDefault(import('../sites/dashsexshop/pages/authorsList/index.vue' /* webpackChunkName: "sites/dashsexshop/pages/authorsList/index" */))
const _334930c3 = () => interopDefault(import('../sites/dashsexshop/pages/blocksettings.vue' /* webpackChunkName: "sites/dashsexshop/pages/blocksettings" */))
const _463d7af0 = () => interopDefault(import('../sites/dashsexshop/pages/promotions/index.vue' /* webpackChunkName: "sites/dashsexshop/pages/promotions/index" */))
const _eea82828 = () => interopDefault(import('../sites/dashsexshop/pages/term/index.vue' /* webpackChunkName: "sites/dashsexshop/pages/term/index" */))
const _79dc6ce8 = () => interopDefault(import('../sites/dashsexshop/pages/search/index.vue' /* webpackChunkName: "sites/dashsexshop/pages/search/index" */))
const _1f3c1b7e = () => interopDefault(import('../sites/dashsexshop/pages/category/index.vue' /* webpackChunkName: "sites/dashsexshop/pages/category/index" */))
const _783d95b6 = () => interopDefault(import('../sites/dashsexshop/pages/contact/index.vue' /* webpackChunkName: "sites/dashsexshop/pages/contact/index" */))
const _1eb2f1c6 = () => interopDefault(import('../sites/dashsexshop/pages/checkout/index.vue' /* webpackChunkName: "sites/dashsexshop/pages/checkout/index" */))
const _dc6dcc6c = () => interopDefault(import('../sites/dashsexshop/pages/articleList/index.vue' /* webpackChunkName: "sites/dashsexshop/pages/articleList/index" */))
const _444292a8 = () => interopDefault(import('../sites/dashsexshop/pages/faq/index.vue' /* webpackChunkName: "sites/dashsexshop/pages/faq/index" */))
const _cfc6ea6c = () => interopDefault(import('../sites/dashsexshop/pages/shoppingGuide/index.vue' /* webpackChunkName: "sites/dashsexshop/pages/shoppingGuide/index" */))
const _5104a2fc = () => interopDefault(import('../sites/dashsexshop/pages/login.vue' /* webpackChunkName: "sites/dashsexshop/pages/login" */))
const _2e387740 = () => interopDefault(import('../sites/dashsexshop/pages/register.vue' /* webpackChunkName: "sites/dashsexshop/pages/register" */))
const _55e1c9c1 = () => interopDefault(import('../sites/dashsexshop/pages/complaint/index.vue' /* webpackChunkName: "sites/dashsexshop/pages/complaint/index" */))
const _7f84e687 = () => interopDefault(import('../sites/dashsexshop/pages/passwordReset.vue' /* webpackChunkName: "sites/dashsexshop/pages/passwordReset" */))
const _6bfa05ca = () => interopDefault(import('../sites/dashsexshop/pages/sitemapconfig.vue' /* webpackChunkName: "sites/dashsexshop/pages/sitemapconfig" */))
const _c5435606 = () => interopDefault(import('../sites/dashsexshop/pages/fittingGuide/index.vue' /* webpackChunkName: "sites/dashsexshop/pages/fittingGuide/index" */))
const _09747966 = () => interopDefault(import('../sites/dashsexshop/pages/maintenance.vue' /* webpackChunkName: "sites/dashsexshop/pages/maintenance" */))
const _0bb3aeea = () => interopDefault(import('../sites/dashsexshop/pages/user/index.vue' /* webpackChunkName: "sites/dashsexshop/pages/user/index" */))
const _f0f30ade = () => interopDefault(import('../sites/dashsexshop/pages/complaintReturn/index.vue' /* webpackChunkName: "sites/dashsexshop/pages/complaintReturn/index" */))
const _05077f84 = () => interopDefault(import('../sites/dashsexshop/pages/aboutPurchase/index.vue' /* webpackChunkName: "sites/dashsexshop/pages/aboutPurchase/index" */))
const _3e4241d2 = () => interopDefault(import('../sites/dashsexshop/pages/passwordForgotten.vue' /* webpackChunkName: "sites/dashsexshop/pages/passwordForgotten" */))
const _37704b1d = () => interopDefault(import('../sites/dashsexshop/pages/brand/index.vue' /* webpackChunkName: "sites/dashsexshop/pages/brand/index" */))
const _9b82352c = () => interopDefault(import('../sites/dashsexshop/pages/checkout/info.vue' /* webpackChunkName: "sites/dashsexshop/pages/checkout/info" */))
const _2d138d2e = () => interopDefault(import('../sites/dashsexshop/pages/checkout/finished.vue' /* webpackChunkName: "sites/dashsexshop/pages/checkout/finished" */))
const _132d84b4 = () => interopDefault(import('../sites/dashsexshop/pages/checkout/methods.vue' /* webpackChunkName: "sites/dashsexshop/pages/checkout/methods" */))
const _65caafe0 = () => interopDefault(import('../sites/dashsexshop/pages/checkout/importOrder/index.vue' /* webpackChunkName: "sites/dashsexshop/pages/checkout/importOrder/index" */))
const _c0b6d36e = () => interopDefault(import('../sites/dashsexshop/pages/user/shoppingLists/index.vue' /* webpackChunkName: "sites/dashsexshop/pages/user/shoppingLists/index" */))
const _0bf69b02 = () => interopDefault(import('../sites/dashsexshop/pages/user/orders/index.vue' /* webpackChunkName: "sites/dashsexshop/pages/user/orders/index" */))
const _46374901 = () => interopDefault(import('../sites/dashsexshop/pages/user/favourites.vue' /* webpackChunkName: "sites/dashsexshop/pages/user/favourites" */))
const _2d98df7a = () => interopDefault(import('../sites/dashsexshop/pages/user/settings.vue' /* webpackChunkName: "sites/dashsexshop/pages/user/settings" */))
const _fb0d7e66 = () => interopDefault(import('../sites/dashsexshop/pages/user/dashboard.vue' /* webpackChunkName: "sites/dashsexshop/pages/user/dashboard" */))
const _2f8be1f2 = () => interopDefault(import('../sites/dashsexshop/pages/user/password.vue' /* webpackChunkName: "sites/dashsexshop/pages/user/password" */))
const _0a1f60ea = () => interopDefault(import('../sites/dashsexshop/pages/checkout/payment/callback.vue' /* webpackChunkName: "sites/dashsexshop/pages/checkout/payment/callback" */))
const _f1460036 = () => interopDefault(import('../sites/dashsexshop/pages/index.vue' /* webpackChunkName: "sites/dashsexshop/pages/index" */))
const _0ac596f1 = () => interopDefault(import('../sites/dashsexshop/pages/user/shoppingLists/_id.vue' /* webpackChunkName: "sites/dashsexshop/pages/user/shoppingLists/_id" */))
const _e0696eb2 = () => interopDefault(import('../sites/dashsexshop/pages/user/orders/_id.vue' /* webpackChunkName: "sites/dashsexshop/pages/user/orders/_id" */))
const _4d95707a = () => interopDefault(import('../sites/dashsexshop/pages/author/_slug.vue' /* webpackChunkName: "sites/dashsexshop/pages/author/_slug" */))
const _dfdb0efc = () => interopDefault(import('../sites/dashsexshop/pages/articleList/_slug.vue' /* webpackChunkName: "sites/dashsexshop/pages/articleList/_slug" */))
const _f2156ab8 = () => interopDefault(import('../sites/dashsexshop/pages/term/_slug.vue' /* webpackChunkName: "sites/dashsexshop/pages/term/_slug" */))
const _69558bdd = () => interopDefault(import('../sites/dashsexshop/pages/infomail/_slug.vue' /* webpackChunkName: "sites/dashsexshop/pages/infomail/_slug" */))
const _496409c4 = () => interopDefault(import('../sites/dashsexshop/pages/article/_slug.vue' /* webpackChunkName: "sites/dashsexshop/pages/article/_slug" */))
const _1218267d = () => interopDefault(import('../sites/dashsexshop/pages/product/_slug.vue' /* webpackChunkName: "sites/dashsexshop/pages/product/_slug" */))
const _987d001e = () => interopDefault(import('../sites/dashsexshop/pages/curatedGuidePage/_slug.vue' /* webpackChunkName: "sites/dashsexshop/pages/curatedGuidePage/_slug" */))
const _49aa5907 = () => interopDefault(import('../sites/dashsexshop/pages/page/_slug.vue' /* webpackChunkName: "sites/dashsexshop/pages/page/_slug" */))
const _196d831c = () => interopDefault(import('../sites/dashsexshop/pages/_.vue' /* webpackChunkName: "sites/dashsexshop/pages/_" */))

const emptyFn = () => {}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: 'active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/aktivovat",
    component: _72eddf6c,
    name: "user-activate___cs"
  }, {
    path: "/autori",
    component: _7f7e259c,
    name: "authorsList___cs"
  }, {
    path: "/blocksettings",
    component: _334930c3,
    name: "blocksettings___cs"
  }, {
    path: "/eroticke-pomucky-v-akci-na-sexshop-cz",
    component: _463d7af0,
    name: "promotions___cs"
  }, {
    path: "/eroticky-vykladovy-slovnik",
    component: _eea82828,
    name: "term___cs"
  }, {
    path: "/hledat",
    component: _79dc6ce8,
    name: "search___cs"
  }, {
    path: "/kategorie",
    component: _1f3c1b7e,
    name: "category___cs"
  }, {
    path: "/kontakt",
    component: _783d95b6,
    name: "contact___cs"
  }, {
    path: "/kosik",
    component: _1eb2f1c6,
    name: "checkout___cs"
  }, {
    path: "/nakupni-radce",
    component: _dc6dcc6c,
    name: "articleList___cs"
  }, {
    path: "/nejcastejsi-dotazy",
    component: _444292a8,
    name: "faq___cs"
  }, {
    path: "/poradna",
    component: _cfc6ea6c,
    name: "shoppingGuide___cs"
  }, {
    path: "/prihlasit",
    component: _5104a2fc,
    name: "login___cs"
  }, {
    path: "/registrovat",
    component: _2e387740,
    name: "register___cs"
  }, {
    path: "/reklamace",
    component: _55e1c9c1,
    name: "complaint___cs"
  }, {
    path: "/resetovat-heslo",
    component: _7f84e687,
    name: "passwordReset___cs"
  }, {
    path: "/sitemapconfig",
    component: _6bfa05ca,
    name: "sitemapconfig___cs"
  }, {
    path: "/tabulka-velikosti",
    component: _c5435606,
    name: "fittingGuide___cs"
  }, {
    path: "/udrzba",
    component: _09747966,
    name: "maintenance___cs"
  }, {
    path: "/uzivatel",
    component: _0bb3aeea,
    name: "user___cs"
  }, {
    path: "/vraceni-zbozi",
    component: _f0f30ade,
    name: "complaintReturn___cs"
  }, {
    path: "/vse-o-nakupu",
    component: _05077f84,
    name: "aboutPurchase___cs"
  }, {
    path: "/zapomenute-heslo",
    component: _3e4241d2,
    name: "passwordForgotten___cs"
  }, {
    path: "/znacky",
    component: _37704b1d,
    name: "brand___cs"
  }, {
    path: "/kosik/dodaci-udaje",
    component: _9b82352c,
    name: "checkout-info___cs"
  }, {
    path: "/kosik/dokonceni",
    component: _2d138d2e,
    name: "checkout-finished___cs"
  }, {
    path: "/kosik/doprava-a-platba",
    component: _132d84b4,
    name: "checkout-methods___cs"
  }, {
    path: "/kosik/import-objednavky",
    component: _65caafe0,
    name: "checkout-importOrder___cs"
  }, {
    path: "/uzivatel/nakupni-seznamy",
    component: _c0b6d36e,
    name: "user-shoppingLists___cs"
  }, {
    path: "/uzivatel/objednavky",
    component: _0bf69b02,
    name: "user-orders___cs"
  }, {
    path: "/uzivatel/oblibene",
    component: _46374901,
    name: "user-favourites___cs"
  }, {
    path: "/uzivatel/osobni-udaje",
    component: _2d98df7a,
    name: "user-settings___cs"
  }, {
    path: "/uzivatel/prehled",
    component: _fb0d7e66,
    name: "user-dashboard___cs"
  }, {
    path: "/uzivatel/zmena-hesla",
    component: _2f8be1f2,
    name: "user-password___cs"
  }, {
    path: "/checkout/payment/callback",
    component: _0a1f60ea,
    name: "checkout-payment-callback___cs"
  }, {
    path: "/",
    component: _f1460036,
    name: "index___cs"
  }, {
    path: "/uzivatel/nakupni-seznamy/:id",
    component: _0ac596f1,
    name: "user-shoppingLists-id___cs"
  }, {
    path: "/uzivatel/objednavky/:id",
    component: _e0696eb2,
    name: "user-orders-id___cs"
  }, {
    path: "/autori/:slug",
    component: _4d95707a,
    name: "author-slug___cs"
  }, {
    path: "/clanky/:slug",
    component: _dfdb0efc,
    name: "articleList-slug___cs"
  }, {
    path: "/eroticky-vykladovy-slovnik/:slug",
    component: _f2156ab8,
    name: "term-slug___cs"
  }, {
    path: "/infomail/:slug?",
    component: _69558bdd,
    name: "infomail-slug___cs"
  }, {
    path: "/nakupni-radce/:slug",
    component: _496409c4,
    name: "article-slug___cs"
  }, {
    path: "/produkt/:slug",
    component: _1218267d,
    name: "product-slug___cs"
  }, {
    path: "/pruvodce/:slug",
    component: _987d001e,
    name: "curatedGuidePage-slug___cs"
  }, {
    path: "/vse-o-nakupu/:slug",
    component: _49aa5907,
    name: "page-slug___cs"
  }, {
    path: "/*",
    component: _196d831c,
    name: "all___cs"
  }],

  fallback: false
}

export function createRouter (ssrContext, config) {
  const base = (config._app && config._app.basePath) || routerOptions.base
  const router = new Router({ ...routerOptions, base  })

  // TODO: remove in Nuxt 3
  const originalPush = router.push
  router.push = function push (location, onComplete = emptyFn, onAbort) {
    return originalPush.call(this, location, onComplete, onAbort)
  }

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    return resolve(to, current, append)
  }

  return router
}
