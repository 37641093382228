import { merge } from 'lodash'
import contentSettings from '~/sites/virtshop/content.settings'

const overloadSettings = {
  cookieBot: {
    cbid: '8cd90439-8b0b-4e68-887a-4a09b392b628',
  },
}

export default merge(contentSettings, overloadSettings)
